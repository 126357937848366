import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const RELOAD_QUERY_KEY = 'reloadedOnce'

export const useChunkLoadErrorHandler = (error: Error) => {
  const searchParams = useSearchParams()

  useEffect(() => {
    if (error.name === 'ChunkLoadError') {
      const pathname = window.location.pathname
      if (searchParams.get(RELOAD_QUERY_KEY) !== 'true') {
        window.location.replace(`${pathname}?${RELOAD_QUERY_KEY}=true`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.name])
}
